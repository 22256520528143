import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import FirebaseManager from "./common/firebase/FirebaseManager";
import { OcelotPay } from "ocelot-pay-sdk";

// init firebase
FirebaseManager.init();

OcelotPay.init({ appId: "dmbXL9DtjzWrT1II3ZVD7ylchBx1-d6sa15TBCubkwDEdT3Hb" });

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
